export class PodcastPlayer {
    constructor() {
        this.audio = new Audio();
        this.isPlaying = false;
        this.currentSpeed = 1;
        this.initializeElements();
        this.setupEventListeners();
    }

    initializeElements() {
        // Buttons
        this.playPauseBtn = document.getElementById('playPauseBtn');
        this.playIcon = this.playPauseBtn.querySelector('.play-icon');
        this.pauseIcon = this.playPauseBtn.querySelector('.pause-icon');
        this.speedBtn = document.getElementById('speedBtn');
        this.volumeBtn = document.getElementById('volumeBtn');

        // Progress elements
        this.progressBar = document.querySelector('.progress-bar');
        this.progress = document.querySelector('.progress');
        this.timeDisplay = document.getElementById('currentTime');

        // Text elements
        this.episodeTitle = document.getElementById('episodeTitle');
    }

    setupEventListeners() {
        // Play/Pause
        this.playPauseBtn.addEventListener('click', () => this.togglePlay());

        // Progress bar
        this.progressBar.addEventListener('click', (e) => this.seek(e));
        this.audio.addEventListener('timeupdate', () => this.updateProgress());
        this.audio.addEventListener('loadedmetadata', () => this.updateProgress());

        // Speed control
        this.speedBtn.addEventListener('click', () => this.toggleSpeed());

        // Volume
        this.volumeBtn.addEventListener('click', () => this.toggleMute());

    }

    loadEpisode(episodeData) {
        this.audio.src = episodeData.audioUrl;
        this.episodeTitle.textContent = episodeData.title;

        // Reset player state
        this.progress.style.width = '0%';
        this.timeDisplay.textContent = '00:00';
        this.isPlaying = false;
        this.updatePlayPauseButton();
    }

    togglePlay() {
        if (this.audio.src) {
            if (this.isPlaying) {
                this.audio.pause();
            } else {
                this.audio.play();
            }
            this.isPlaying = !this.isPlaying;
            this.updatePlayPauseButton();
        }
    }

    play() {
        if (this.audio.src) {
            this.audio.play();
            this.isPlaying = true;
            this.updatePlayPauseButton();
        }
    }

    updatePlayPauseButton() {
        if (this.isPlaying) {
            this.playIcon.classList.add('hidden');
            this.pauseIcon.classList.remove('hidden');
        } else {
            this.playIcon.classList.remove('hidden');
            this.pauseIcon.classList.add('hidden');
        }
    }

    seek(event) {
        const rect = this.progressBar.getBoundingClientRect();
        const percent = (event.clientX - rect.left) / rect.width;
        this.audio.currentTime = percent * this.audio.duration;
    }

    updateProgress() {
        if (this.audio.duration) {
            const percent = (this.audio.currentTime / this.audio.duration) * 100;
            this.progress.style.width = `${percent}%`;
            this.timeDisplay.textContent = this.formatTime(this.audio.currentTime);
        }
    }

    toggleSpeed() {
        const speeds = [1, 1.5, 2, 0.5];
        const currentIndex = speeds.indexOf(this.currentSpeed);
        this.currentSpeed = speeds[(currentIndex + 1) % speeds.length];
        this.audio.playbackRate = this.currentSpeed;
        this.speedBtn.textContent = `${this.currentSpeed}x`;
    }

    toggleMute() {
        this.audio.muted = !this.audio.muted;
        this.volumeBtn.textContent = this.audio.muted ? '🔇' : '🔊';
    }

    formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        seconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
}
