import { Controller } from "@hotwired/stimulus"
import { PodcastPlayer } from "../packs/podcast-player"

export default class extends Controller {
  static targets = ["player"]
  static values = {
    audioUrl: String,
    episodeTitle: String,
    podcastTitle: String
  }

  connect() {
    // Controller will only initialize if the player target exists
    if (this.hasPlayerTarget) {
      window.podcastPlayer = window.podcastPlayer || new PodcastPlayer()
    }
  }

  play() {
    if (!window.podcastPlayer) {
      window.podcastPlayer = new PodcastPlayer()
    }
    const player = window.podcastPlayer

    if (player.audio.src !== this.audioUrlValue) {
      player.audio.src = this.audioUrlValue
      document.getElementById('episodeTitle').textContent = this.episodeTitleValue
    }

    player.play()
    document.querySelector('.podcast-player').classList.remove('hidden')

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'episodeClick',
      episodeTitle: this.episodeTitleValue,
      podcastTitle: this.podcastTitleValue
    })
  }

  close() {
    const player = window.podcastPlayer
    player.audio.pause()
    document.querySelector('.podcast-player').classList.add('hidden')
  }
}
